.viewMenuWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 5vh auto;
}

.viewMenuText {
  font-family: Comfortaa, sans-serif;
  font-weight: 600;
}
