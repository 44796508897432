@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.viewMenuWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 5vh auto;
}

.viewMenuText {
  font-family: Comfortaa, sans-serif;
  font-weight: 600;
}

.rct-text label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: small;
    overflow: hidden;
  }

.rct-checkbox {
    height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2px;
}
