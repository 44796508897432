.rct-text label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: small;
    overflow: hidden;
  }

.rct-checkbox {
    height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2px;
}